import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonImg,
    IonLoading,
} from '@ionic/react';
import firebaseApp from '../firebase';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import fplogo from '../assets/images/firepermitonline.png';
// import fplogoW from '../assets/images/firepermitonline-w.png';
import {
    appsOutline,
    appsSharp,
    banOutline,
    banSharp,
    bonfireOutline,
    bonfireSharp,
    addCircleOutline,
    addCircleSharp,
    cogOutline,
    cogSharp,
    megaphoneOutline,
    megaphoneSharp,
    logOutOutline,
    logOutSharp,
} from 'ionicons/icons';
import './Menu.css';

const appPages = [
    {
        title: 'Dashboard',
        url: '/dashboard',
        iosIcon: appsOutline,
        mdIcon: appsSharp,
    },
    {
        title: 'All Permits',
        url: '/permits',
        iosIcon: bonfireOutline,
        mdIcon: bonfireSharp,
    },
    {
        title: 'New Permit',
        url: '/permits/new',
        iosIcon: addCircleOutline,
        mdIcon: addCircleSharp,
    },
    {
        title: 'Announcements',
        url: '/announcements',
        iosIcon: megaphoneOutline,
        mdIcon: megaphoneSharp,
    },
    {
        title: 'Exclusions',
        url: '/exclusions',
        iosIcon: banOutline,
        mdIcon: banSharp,
    },
];

// let api = 'https://us-central1-*****.cloudfunctions.net/payments';

const Menu = () => {
    // const isDark = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
    const location = useLocation();
    const history = useHistory();
    const logOut = () => {
        firebaseApp.auth().signOut();
        history.push('/login', { direction: 'none' });
    };
    const [showLoading, setShowLoading] = useState(false);

    // async function getLoginLink() {
    //   setShowLoading(true);
    //   const url = api + '/loginLink';
    //   const params = {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     // mode: 'no-cors'
    //   };

    //   return (
    //     await fetch(url, params)
    //     .then(response => response.json())
    //     .then(data => {
    //       setShowLoading(false);
    //       window.open(data.url, "_blank");
    //     })
    //     .catch((error) => console.log(error))
    //   );
    // }
    return (
        <IonMenu contentId='main' type='overlay'>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Establishing secure connection...'}
            />
            <IonContent>
                <IonList id='inbox-list'>
                    <IonListHeader>
                        <IonImg src={fplogo} />
                    </IonListHeader>
                    <IonNote>Cochrane, Ontario</IonNote>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url}
                                    routerDirection='none'
                                    lines='none'
                                    detail={false}
                                >
                                    <IonIcon
                                        slot='start'
                                        color={appPage.color}
                                        ios={appPage.iosIcon}
                                        md={appPage.mdIcon}
                                    />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
                <IonList>
                    {/* <IonMenuToggle autoHide={false}>
            <IonItem button lines="none" detail={false} onClick={() => getLoginLink()}>
              <IonIcon slot="start" color={'primary'} ios={logoUsd} md={logoUsd} />
              <IonLabel>Payouts</IonLabel>
              <IonIcon slot="end" color={'primary'} ios={openOutline} md={openOutline} />
            </IonItem>
          </IonMenuToggle> */}
                    <IonMenuToggle autoHide={false}>
                        <IonItem lines='none' routerLink={'/settings'} routerDirection='none'>
                            <IonIcon
                                slot='start'
                                color={'primary'}
                                ios={cogOutline}
                                md={cogSharp}
                            />
                            <IonLabel>Settings</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle autoHide={false}>
                        <IonItem lines='none' button onClick={logOut}>
                            <IonIcon
                                slot='start'
                                color={'primary'}
                                ios={logOutOutline}
                                md={logOutSharp}
                            />
                            <IonLabel>Log Out</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
