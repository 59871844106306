import { Store } from "pullstate";

export const PermitStore = new Store({
    permits: [],
    applicationModal: false,
    applicationStep: 0,
    terms: null,
    application: {
        type: null,
        address: null,
        otherAddress: null,
        price: null,
    },
    activities: [],
});
