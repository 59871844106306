import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { toast } from './components/toast';

const firebaseConfig = {
    apiKey: 'AIzaSyBW84begEC5MHffLzaIep6YV3F7p4vRiog',
    authDomain: 'cochranefpo.firebaseapp.com',
    projectId: 'cochranefpo',
    storageBucket: 'cochranefpo.appspot.com',
    messagingSenderId: '711529017996',
    appId: '1:711529017996:web:4bacdaaae7e7e97ba701a9',
    measurementId: 'G-CYHJ19PFDG',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);
        console.log(res);
        return true;
    } catch (error) {
        toast(error.message, 4000);
        return false;
    }
}

export function logoutUser() {
    return firebase.auth().signOut();
}
