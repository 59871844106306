import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonItem,
    IonTextarea,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonButton,
    IonIcon,
    IonModal,
    IonLabel,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonListHeader,
    IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import './Page.css';
import firebaseApp from '../firebase';
import { toast } from '../components/toast';
import { useHistory } from 'react-router';
import { banOutline, checkmarkCircle, closeOutline, eyeOutline, mailOutline } from 'ionicons/icons';
import Reasons from '../assets/data/Reasons';
import moment from 'moment';
import { PermitStore } from '../store/PermitStore';
import CurrentToggle from '../components/CurrentToggle';
import { getPermits, getCurrentPermits } from '../utilityFunctions';

const fbDb = firebaseApp.firestore().collection('permits');

const Permits = () => {
    const history = useHistory();
    const currentPermits = PermitStore.useState((s) => s.currentPermits);
    const permits = PermitStore.useState((s) => s.permits);
    const currentOnly = PermitStore.useState((s) => s.currentOnly);
    const permitsList = currentOnly ? currentPermits : !currentOnly ? permits : null;

    const [showModal, setShowModal] = useState({
        isOpen: false,
        data: null,
    });
    const [reason, setReason] = useState({ reason: null, desc: null, sendViaEmail: false });
    const [refund, setRefund] = useState(false);
    const cancelReasons = Reasons;

    const setActive = (value, data) => {
        fbDb.doc(value)
            .update({
                active: !data.rowData[1],
            })
            .then(() => {
                toast('Permit Updated', 3000, 'success');
                getPermits();
                getCurrentPermits();
            })
            .catch((err) => console.log(err));
    };

    const options = {
        filterType: 'multiselect',
        elevation: 0,
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
        selectableRows: 'none',
        customToolbarSelect: () => {},

        // filename: 'permits.csv'
    };
    const columns = [
        {
            name: 'id',
            label: 'View',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, rowData) => (
                    <IonButton
                        fill='clear'
                        color={'primary'}
                        onClick={() => history.push(`/permit/${value}`)}
                        slot='icon-only'
                    >
                        <IonIcon icon={eyeOutline} />
                    </IonButton>
                ),
            },
        },
        {
            name: 'active',
            label: 'Active',
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            name: 'propertyOwner',
            label: 'Property Owner',
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            name: 'inspectionStatus',
            label: 'Insp. Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) =>
                    !value ? (
                        <IonText color='warning'>Incomplete</IonText>
                    ) : (
                        <IonText color={value === 'Failed' ? 'danger' : 'success'}>{value}</IonText>
                    ),
            },
        },
        {
            name: 'id',
            label: 'Active?',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, data) => (
                    <>
                        {data.rowData[1] === 'Cancelled' ? (
                            'Cancelled'
                        ) : (
                            <IonButton
                                fill='clear'
                                color={
                                    data.rowData[1] === true
                                        ? 'success'
                                        : data.rowData[1] === false
                                        ? 'medium'
                                        : data.rowData[1] === 'Cancelled'
                                        ? 'danger'
                                        : 'medium'
                                }
                                onClick={() => setActive(value, data)}
                                disabled={data.rowData[1] === 'Cancelled'}
                            >
                                {data.rowData[1] === true ? (
                                    'Yes'
                                ) : data.rowData[1] === 'Cancelled' ? (
                                    <IonIcon icon={banOutline} slot='icon-only' />
                                ) : (
                                    'No'
                                )}
                            </IonButton>
                        )}
                    </>
                ),
            },
        },
        {
            name: 'paymentId',
            label: 'Paid',
            options: {
                display: false,
                filter: false,
                sort: true,
                customBodyRender: (value) =>
                    value ? <IonIcon icon={checkmarkCircle} color='success' /> : null,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) =>
                    value !== null ? (
                        <IonButton fill='clear' href={`mailto:${value}`}>
                            <IonIcon icon={mailOutline} />
                        </IonButton>
                    ) : (
                        'Unavailable'
                    ),
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'type',
            label: 'Type',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'address',
            label: 'Location',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, rowData) => `${value === 'other' ? 'Other' : value}`,
            },
        },
        {
            name: 'businessName',
            label: 'Business',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'otherAddress',
            label: 'Other Address',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'firstName',
            label: 'First Name',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'lastName',
            label: 'Last Name',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'from',
            label: 'Valid From',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <IonText>{moment(value).format('YYYY-MM-DD')}</IonText>
                ),
            },
        },
        {
            name: 'to',
            label: 'Valid To',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <IonText
                        color={
                            moment(value).format('YYYY-MM-DD') <
                            moment(Date.now()).format('YYYY-MM-DD')
                                ? 'danger'
                                : 'sucess'
                        }
                    >
                        {moment(value).format('YYYY-MM-DD')}
                    </IonText>
                ),
            },
        },
        {
            name: 'paymentId',
            label: 'Payment ID',
            options: {
                display: false,
                filter: false,
                sort: true,
                customBodyRender: (value) =>
                    value ? value.substring(value.length - 5, value.length) : null,
            },
        },
        {
            name: 'id',
            label: 'Cancel?',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, data) =>
                    data.rowData[1] !== 'Cancelled' ? (
                        <IonButton
                            fill={'clear'}
                            color={'primary'}
                            onClick={() => {
                                // console.log(data);
                                setShowModal({
                                    isOpen: true,
                                    data: data,
                                });
                                // setPermit(permits[data.rowIndex]);
                            }}
                        >
                            <IonIcon icon={banOutline} slot='icon-only' />
                        </IonButton>
                    ) : (
                        'Cancelled'
                    ),
            },
        },
        {
            name: 'user',
            label: 'user',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
    ];

    const confirmCancel = (id, status, user) => {
        console.log(id, status, user);
        if (status === 'Cancelled') {
            fbDb.doc(id)
                .update({
                    active: false,
                    reason: null,
                })
                .then(() => {
                    firebaseApp
                        .firestore()
                        .collection('users')
                        .doc(user)
                        .collection('permits')
                        .doc(id)
                        .update({
                            active: false,
                            reason: null,
                        });
                })
                .then(() => {
                    setShowModal({
                        isOpen: false,
                        data: null,
                    });
                    toast('Permit Reinstated');
                });
        } else {
            fbDb.doc(id)
                .update({
                    active: 'Cancelled',
                    reason,
                })
                .then(() => {
                    firebaseApp
                        .firestore()
                        .collection('users')
                        .doc(user)
                        .collection('permits')
                        .doc(id)
                        .update({
                            active: 'Cancelled',
                            reason,
                        });
                })
                .then(() => {
                    setShowModal({
                        isOpen: false,
                        data: null,
                    });
                    toast('Permit Cancelled');
                });
        }
        if (reason.sendViaEmail === true) {
            const sendEmail = firebaseApp.functions().httpsCallable('cxlEmail');
            sendEmail({ reason: reason.desc })
                .then(() => {
                    console.log('email request sent');
                    toast('Notification sent via Email');
                })
                .catch((err) => {
                    console.log(err);
                    console.log(err);
                });
        }
    };

    const clearCancel = () => {
        setShowModal({
            isOpen: false,
            data: null,
        });
        setReason({ reason: null, desc: null });
    };

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>All Permits</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonModal
                mode='ios'
                isOpen={showModal.isOpen}
                cssClass='my-custom-class'
                swipeToClose={true}
                onDidDismiss={clearCancel}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Cancel Permit?</IonTitle>
                        <IonButtons slot='end'>
                            <IonIcon
                                slot='icon-only'
                                icon={closeOutline}
                                onClick={() =>
                                    setShowModal({
                                        isOpen: false,
                                        data: null,
                                    })
                                }
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='ion-padding'>
                    <p className='ion-text-center ion-padding-bottom'>
                        <strong>{showModal.data ? showModal.data.rowData[0] : null}</strong>
                    </p>
                    {/* <IonItemDivider>Include a message to applicant?</IonItemDivider> */}
                    <IonItem>
                        <IonLabel position='stacked' className='ion-padding-bottom'>
                            Reason
                        </IonLabel>
                        <IonSelect
                            placeholder='Select One'
                            onIonChange={(e) =>
                                setReason(
                                    e.detail.value !== 'Other'
                                        ? cancelReasons[e.detail.value]
                                        : { reason: 'Other', desc: null }
                                )
                            }
                        >
                            {cancelReasons &&
                                cancelReasons.map((rea, i) => (
                                    <IonSelectOption key={i} value={i}>
                                        {rea.reason}
                                    </IonSelectOption>
                                ))}
                            <IonSelectOption value='Other'>Other</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {reason !== null ? (
                        <IonItem>
                            <IonLabel position='stacked' className='ion-padding-bottom'>
                                Explanation
                            </IonLabel>
                            {reason && reason.reason === 'Other' ? (
                                <IonTextarea
                                    rows='4'
                                    placeholder='Enter reasoning here...'
                                    value={reason.desc}
                                    onIonChange={(e) =>
                                        setReason({ reason: 'Other', desc: e.detail.value })
                                    }
                                ></IonTextarea>
                            ) : reason && reason !== null ? (
                                <p>{reason.desc}</p>
                            ) : null}
                        </IonItem>
                    ) : null}
                    {showModal.data && showModal.data.rowData[13] ? (
                        <IonItem>
                            <IonLabel>Issue refund?</IonLabel>
                            <IonToggle
                                disabledchecked={refund}
                                onIonChange={(e) => setRefund(e.detail.checked)}
                            />
                        </IonItem>
                    ) : null}
                    <IonListHeader>Notify user?</IonListHeader>
                    <IonItem>
                        <IonLabel>Via email</IonLabel>
                        <IonToggle
                            disabledchecked={refund}
                            onIonChange={(e) =>
                                setReason({ ...reason, sendViaEmail: e.detail.checked })
                            }
                        />
                    </IonItem>
                    {/* <IonItem>
            <IonLabel>Via SMS</IonLabel>
            <IonToggle disabledchecked={refund} onIonChange={e => setReason(e.detail.checked)} />
          </IonItem> */}
                </IonContent>
                <IonButton
                    disabled={reason === null}
                    onClick={() =>
                        confirmCancel(
                            showModal.data.rowData[0],
                            showModal.data.rowData[1],
                            showModal.data.rowData[15]
                        )
                    }
                >
                    Confirm Cancellation
                </IonButton>
                <IonButton color='medium' onClick={clearCancel}>
                    Close
                </IonButton>
            </IonModal>
            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>All Permits</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <CurrentToggle />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <MUIDataTable
                                    title={'Permits List'}
                                    data={permitsList}
                                    columns={columns}
                                    options={options}
                                />
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Permits;
