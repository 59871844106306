import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCardHeader,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonToggle,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardContent,
    IonListHeader,
    IonLabel,
    IonInput,
    IonCardTitle,
    IonButton,
    IonSpinner,
    IonSelectOption,
    IonSelect,
    IonBadge,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import Geocode from 'react-geocode';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import moment from 'moment';
// import GoogleMapReact from 'google-map-react';
// import FireMarker from '../components/FireMarker/FireMarker';
import CheckoutForm from '../components/CheckoutForm';
import { useHistory } from 'react-router';
import firebaseApp from '../firebase';
import { PermitStore } from '../store/PermitStore';
import { TownStore } from '../store/TownStore';

Geocode.setApiKey('AIzaSyBjiABpysbhr_3eI56zyfM6h1WAIIhjKgU');
// set response language. Defaults to english.
Geocode.setLanguage('en');
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('ca');
// Enable or disable logs. Its optional.
Geocode.enableDebug();
// const mapKey = 'AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY';

const fbDb = firebaseApp.firestore();
// const user = firebaseApp.auth().currentUser;
const New = () => {
    const history = useHistory();
    const [manual, setManual] = useState(false);
    const [manualAddress, setManualAddress] = useState('');
    const [paymentReady, setPaymentReady] = useState(false);
    const [randomId, setRandomId] = useState(null);
    const [geocode, setGeocode] = useState(null);
    const permitTypes = PermitStore.useState((s) => s.permitTypes);
    const townDoc = TownStore.useState((s) => s.townDoc);
    const [permit, setPermit] = useState({
        businessName: null,
        firstName: null,
        lastName: null,
        startDate: Date.now(),
        endDate: moment(Date.now()).format('YYYY') + '-12-31',
        email: null,
        phone: null,
        type: null,
        address: '',
        from: moment(Date.now()).format('YYYY-MM-DD'),
        to: moment(Date.now()).format('YYYY') + '-12-31',
        paymentReceived: false,
        deptIssued: true,
        prepaid: false,
        propertyOwner: null,
        propertyOwnerAddress: null,
        activities: [],
    });

    const [activitiesChoices, setActivitiesChoices] = useState(null);
    const [activities, setActivities] = useState([]);
    // const permitTypes = applicationData.application.types;

    const getGeoCode = () => {
        if (!manualAddress && permit.address) {
            Geocode.fromAddress(permit.address).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    // console.log(lat, lng);
                    setGeocode({ lat: lat, lng: lng });
                    setPermit({ ...permit, latitude: lat, longitude: lng });
                },
                (error) => {
                    console.error('geocode', error);
                }
            );
        }
    };

    // useEffect(() => {
    //     console.log(permit);
    // }, [permit]);
    // function _onClick(obj) {
    //     console.log(obj.lat, obj.lng);
    //     setGeocode({ lat: obj.lat, lng: obj.lng });
    // }
    // useEffect(() => {
    //     console.log(permit.activities);
    // }, [permit.activities]);

    useEffect(() => {
        getGeoCode();
    }, [permit.address]);

    useEffect(() => {
        if (permit.email !== null) {
            setTimeout(() => {
                setPaymentReady(true);
            }, 5000);
        }
    });
    const handleChange = (e) => {
        const { name, paid, price, validFor, activities } = e.detail.value;

        if (activities.length) {
            setActivitiesChoices(activities);
        }

        if (validFor === 'EOY') {
            // console.log(dayjs(Date.now()).format('YYYY') + '-12-31');
            setPermit({
                ...permit,
                type: name,
                paid,
                price,
                validFor,
                to: moment(Date.now()).format('YYYY') + '-12-31',
                endDate: moment(Date.now()).format('YYYY') + '-12-31',
            });
        } else {
            setPermit({
                ...permit,
                type: name,
                paid,
                price,
                validFor,
                to: Date.now() + validFor,
                endDate: Date.now() + validFor,
            });
        }
    };
    useEffect(() => {
        setPermit({
            ...permit,
            activities: activities,
        });
    }, [activities]);
    useEffect(() => {
        if (manualAddress) {
            setPermit({
                ...permit,
                address: manualAddress,
            });
        }
    }, [manualAddress]);
    // console.log(user)
    const createPermit = () => {
        // console.log(permit)
        fbDb.collection('permits')
            .doc(permit.lastName + '-' + permit.type + '-' + permit.startDate)
            .set({
                activities: permit.activities,
                firstName: permit.firstName ? permit.firstName : null,
                lastName: permit.lastName ? permit.lastName : null,
                type: permit.type ? permit.type : null,
                from: permit.from ? permit.from : null,
                to: permit.to ? permit.to : null,
                details: permit.details ? permit.details : null,
                startDate: permit.startDate ? moment(permit.startDate).format('YYYY-MM-DD') : null,
                endDate: permit.endDate ? moment(permit.endDate).format('YYYY') + '-12-31' : null,
                address: permit.address ? permit.address : null,
                businessName: permit.businessName ? permit.businessName : null,
                latitude: !manualAddress ? geocode.lat : townDoc.latitude,
                longitude: !manualAddress ? geocode.lng : townDoc.longitude,
                checkedAgree: permit.checkedAgree ? permit.checkedAgree : null,
                checkedRead: permit.checkedRead ? permit.checkedRead : null,
                active: false,
                user: permit.email,
                email: permit.email,
                phone: permit.phone,
                paymentId: permit.prepaid ? randomId : null,
            })
            .then(() => {
                setTimeout(() => {
                    history.push(
                        `/success/${permit.lastName + '-' + permit.type + '-' + permit.startDate}`
                    );
                }, 1500);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        setRandomId('mc_' + makeid(22));
    }, []);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='primary' mode='ios'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Add New Permit</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Add New Permit</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonCardHeader>
                                    <IonCardSubtitle>Create a new</IonCardSubtitle>
                                    <IonCardTitle>Burn Permit</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>Applicant Details</IonListHeader>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Email Address
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter resident email'
                                                            debounce='3000'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    email: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            First Name
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter first name'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    firstName: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Last Name
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter last name'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    lastName: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Phone Number
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter resident phone'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    phone: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    {!manual ? (
                                                        <IonItem>
                                                            <IonLabel position='stacked'>
                                                                Address
                                                            </IonLabel>
                                                            <GooglePlacesAutocomplete
                                                                autocompletionRequest={{
                                                                    componentRestrictions: {
                                                                        country: ['ca'],
                                                                    },
                                                                    bounds: [
                                                                        {
                                                                            lat: townDoc.s,
                                                                            lng: townDoc.w,
                                                                        },
                                                                        {
                                                                            lat: townDoc.n,
                                                                            lng: townDoc.e,
                                                                        },
                                                                    ],
                                                                    strictBounds: true,
                                                                }}
                                                                onSelect={({
                                                                    structured_formatting,
                                                                }) => {
                                                                    // console.log({
                                                                    //     address:
                                                                    //         structured_formatting.main_text,
                                                                    // });
                                                                    setPermit({
                                                                        ...permit,
                                                                        address:
                                                                            structured_formatting.main_text +
                                                                            ', ' +
                                                                            structured_formatting.secondary_text,
                                                                    });
                                                                }}
                                                                displayFromSuggestionSelected={({
                                                                    structured_formatting,
                                                                }) =>
                                                                    structured_formatting.main_text
                                                                }
                                                                // apiKey={mapKey}
                                                                inputClassName='addressBox'
                                                                placeholder={'Enter address'}
                                                                initialValue={permit.address || ''}
                                                                suggestionsClassNames={{
                                                                    container:
                                                                        'suggestionContainer',
                                                                    suggestion: 'suggestionItem',
                                                                    suggestionActive:
                                                                        'suggestionActive',
                                                                }}
                                                            />
                                                        </IonItem>
                                                    ) : (
                                                        <IonItem>
                                                            <IonLabel position='stacked'>
                                                                Manually enter address
                                                            </IonLabel>
                                                            <IonInput
                                                                debounce={'3000'}
                                                                placeholder='Enter resident address'
                                                                onIonChange={(e) => {
                                                                    setManualAddress(
                                                                        e.detail.value
                                                                    );
                                                                }}
                                                            />
                                                        </IonItem>
                                                    )}
                                                    <p>
                                                        <IonButton
                                                            fill='clear'
                                                            onClick={() => setManual(!manual)}
                                                            style={{
                                                                textDecoration: 'underline',
                                                            }}
                                                            color='secondary'
                                                            size='small'
                                                        >
                                                            Toggle Manual Entry
                                                        </IonButton>
                                                    </p>
                                                    {/* <IonButton
                                                        slot='end'
                                                        disabled={
                                                            permit.address === null ? true : false
                                                        }
                                                        onClick={() => getGeoCode()}
                                                    >
                                                        Get Coordinates
                                                    </IonButton> */}
                                                    {/* {/* <div
                                                        style={{
                                                            height: '292.5px',
                                                            width: '100%',
                                                            marginLeft: 5,
                                                            marginTop: 5,
                                                        }}
                                                        className='ion-padding-horizontal'
                                                    >
                                                        <GoogleMapReact
                                                            onClick={_onClick}
                                                            bootstrapURLKeys={{
                                                                key: mapKey,
                                                                libraries: ['places'],
                                                            }}
                                                            center={
                                                                geocode !== null
                                                                    ? {
                                                                          lat: geocode.lat,
                                                                          lng: geocode.lng,
                                                                      }
                                                                    : {
                                                                          lat: municipality.lat,
                                                                          lng: municipality.lng,
                                                                      }
                                                            }
                                                            defaultZoom={17}
                                                            options={(maps) => ({
                                                                mapTypeControl: true,
                                                                mapTypeId: maps.MapTypeId.HYBRID,
                                                            })}
                                                        >
                                                            <FireMarker
                                                                lat={
                                                                    geocode !== null
                                                                        ? geocode.lat
                                                                        : municipality.lat
                                                                }
                                                                lng={
                                                                    geocode !== null
                                                                        ? geocode.lng
                                                                        : municipality.lng
                                                                }
                                                                text='My Marker'
                                                                play={true}
                                                                color='warning'
                                                            />
                                                        </GoogleMapReact>
                                                        {permit.latitude ? (
                                                            <p>
                                                                Coodinates: {permit.latitude},
                                                                {permit.longitude}
                                                            </p>
                                                        ) : null}
                                                    </div> */}
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>
                                                        Property Owner (if different)
                                                    </IonListHeader>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Property Owner
                                                        </IonLabel>
                                                        <IonInput
                                                            lines='inset'
                                                            placeholder='Enter name'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    propertyOwner: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                        <IonLabel position='stacked'>
                                                            Property Owner Address
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter address'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    propertyOwnerAddress:
                                                                        e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>Permit Type</IonListHeader>
                                                    <IonRadioGroup
                                                        mode='md'
                                                        aria-label={'List of burn permits'}
                                                        checked={permit.type}
                                                        onIonChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        {permitTypes
                                                            ? permitTypes.map((type, i) => {
                                                                  return (
                                                                      <div key={i}>
                                                                          <IonItem
                                                                              aria-label={
                                                                                  'Burn permit type'
                                                                              }
                                                                              lines='none'
                                                                          >
                                                                              <IonLabel className='ion-text-wrap'>
                                                                                  {type.name}{' '}
                                                                                  {type.desc
                                                                                      ? '-'
                                                                                      : ''}{' '}
                                                                                  {type.desc}
                                                                              </IonLabel>
                                                                              <IonRadio
                                                                                  slot='start'
                                                                                  value={type}
                                                                              />
                                                                              <IonBadge slot='end'>
                                                                                  ${type.price}
                                                                              </IonBadge>
                                                                          </IonItem>
                                                                      </div>
                                                                  );
                                                              })
                                                            : null}
                                                    </IonRadioGroup>
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>Select Activities</IonListHeader>
                                                    <>
                                                        <h3 className='ion-padding'>
                                                            Please select all burning activities
                                                            that apply:
                                                        </h3>
                                                        <IonSelect
                                                            disabled={!activitiesChoices}
                                                            multiple={true}
                                                            value={activities}
                                                            onIonChange={(e) =>
                                                                setActivities(e.detail.value)
                                                            }
                                                            placeholder='Please select all that apply'
                                                        >
                                                            {activitiesChoices &&
                                                                activitiesChoices.map((val, i) => (
                                                                    <IonSelectOption
                                                                        value={val}
                                                                        key={i}
                                                                    >
                                                                        {val}
                                                                    </IonSelectOption>
                                                                ))}
                                                        </IonSelect>
                                                    </>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol xs={12} md={6}>
                                                <IonList>
                                                    <IonListHeader>Payment</IonListHeader>

                                                    {permit.type === null ? (
                                                        <div className='ion-padding'>
                                                            <h2>Please select a permit type</h2>
                                                        </div>
                                                    ) : permit.type === 'Farm' ? (
                                                        <div className='ion-padding'>
                                                            <h2>Payment not required.</h2>
                                                            <IonButton
                                                                className='ion-padding-vertical'
                                                                expand='block'
                                                                onClick={() => {
                                                                    createPermit();
                                                                }}
                                                            >
                                                                Submit Application
                                                            </IonButton>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {paymentReady === false ? (
                                                                <div className='ion-padding'>
                                                                    <div className='ion-padding ion-text-center'>
                                                                        <IonGrid>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    <IonItem>
                                                                                        <IonLabel>
                                                                                            Prepaid?{' '}
                                                                                        </IonLabel>
                                                                                        <IonToggle
                                                                                            checked={
                                                                                                permit.prepaid
                                                                                            }
                                                                                            onIonChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setPermit(
                                                                                                    {
                                                                                                        ...permit,
                                                                                                        prepaid:
                                                                                                            !permit.prepaid,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </IonItem>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    {permit.prepaid ? (
                                                                                        <IonButton
                                                                                            className='ion-padding-vertical'
                                                                                            expand='block'
                                                                                            onClick={() => {
                                                                                                createPermit();
                                                                                            }}
                                                                                        >
                                                                                            Submit
                                                                                            Application
                                                                                        </IonButton>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <IonSpinner name='lines' />
                                                                                            <br />
                                                                                            {permit.email ===
                                                                                            null
                                                                                                ? 'Please add resident email address'
                                                                                                : 'Connecting to Stripe for payment'}
                                                                                        </div>
                                                                                    )}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonGrid>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='ion-padding-horizontal'>
                                                                    <div className='ion-padding-horizontal'>
                                                                        <IonGrid>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    <IonItem>
                                                                                        <IonLabel>
                                                                                            Prepaid?{' '}
                                                                                        </IonLabel>
                                                                                        <IonToggle
                                                                                            checked={
                                                                                                permit.prepaid
                                                                                            }
                                                                                            onIonChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setPermit(
                                                                                                    {
                                                                                                        ...permit,
                                                                                                        prepaid:
                                                                                                            !permit.prepaid,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </IonItem>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    {permit.prepaid ? (
                                                                                        <IonButton
                                                                                            className='ion-padding-vertical'
                                                                                            expand='block'
                                                                                            onClick={() => {
                                                                                                createPermit();
                                                                                            }}
                                                                                        >
                                                                                            Submit
                                                                                            Application
                                                                                        </IonButton>
                                                                                    ) : (
                                                                                        <CheckoutForm
                                                                                            permit={
                                                                                                permit
                                                                                            }
                                                                                            geocode={
                                                                                                geocode
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonGrid>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default New;
