import React, { useState, useEffect } from 'react';
import { IonLoading, IonButton } from '@ionic/react';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router';
import firebaseApp from '../firebase';
import { CardElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import moment from 'moment';
import './CheckoutForm.css';
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
        hidePostalCode: true,
    },
};
let api = 'https://us-central1-cochranefpo.cloudfunctions.net/payments';
// let stripe = window.Stripe('sk_test_ms2BsbsGpjBz5TIQ4VeoBPPe009edxGQ7v');
const fbDb = firebaseApp.firestore();

const Form = (props) => {
    const elements = useElements();
    const stripe = useStripe();
    const history = useHistory();
    // const [ name, setName ] = useState('');
    // const [ postal, setPostal ] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const { permit, geocode } = props;
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    async function createIntent() {
        if (permit.email !== null) {
            const url = api + '/intents';
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: permit.email,
                    price: 2000,
                }),
                // mode: 'no-cors'
            };

            return await fetch(url, params)
                .then((response) => response.json())
                .then((data) => {
                    console.log('PI Created');
                    setPaymentIntent(data);
                })
                .catch((error) => console.log('PI Not Created, ' + error));
        }
    }

    useEffect(() => {
        createIntent();
    }, [permit.email]);

    useEffect(() => {
        if (paymentId !== null) {
            createPermit();
            setShowLoading(false);
        } else return;
    }, [paymentId]);

    const handleChange = (event) => {
        if (event.error) {
            setErrorMessage(event.error.message);
        } else {
            setErrorMessage(null);
        }
    };
    const createPermit = () => {
        console.log(permit);
        fbDb.collection('permits')
            .doc(permit.lastName + '-' + permit.type + '-' + permit.startDate)
            .set({
                activities: permit.activities,
                firstName: permit.firstName ? permit.firstName : null,
                lastName: permit.lastName ? permit.lastName : null,
                type: permit.type ? permit.type : null,
                startDate: permit.startDate ? moment(permit.startDate).format('YYYY-MM-DD') : null,
                from: permit.from ? permit.from : null,
                endDate: permit.endDate ? moment(Date.now()).format('YYYY') + '-12-31' : null,
                to: permit.to ? moment(Date.now()).format('YYYY') + '-12-31' : null,
                details: permit.details ? permit.details : null,
                address: permit.address ? permit.address : null,
                otherAddress: permit.otherLocation ? permit.otherLocation : null,
                businessName: permit.businessName ? permit.businessName : null,
                latitude: geocode.lat ? geocode.lat : null,
                longitude: geocode.lng ? geocode.lng : null,
                checkedAgree: true,
                checkedRead: true,
                active: false,
                email: permit.email,
                paymentId: paymentId ? paymentId : null,
            })
            .then(() => {
                setTimeout(() => {
                    history.push(
                        `/success/${permit.lastName + '-' + permit.type + '-' + permit.startDate}`
                    );
                }, 1500);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setShowLoading(true);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: {
                card: card,
                billing_details: {
                    name: permit.firstName + ' ' + permit.lastName,
                },
            },
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            setErrorMessage(result.error.message);
            setShowLoading(false);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                // console.log(result);
                setPaymentId(result.paymentIntent.id);
                // createPermit();
            }
        }
    };

    return (
        <div>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                    <label htmlFor='card-element'>Credit or debit card</label>
                    <CardElement
                        id='card-element'
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChange}
                    />
                    <div className='card-errors' role='alert'>
                        {errorMessage}
                    </div>
                </div>
                <IonButton expand='block' color='success' type='submit'>
                    Submit Payment
                </IonButton>
            </form>
        </div>
    );
};

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// const stripePromise = loadStripe('pk_test_tOaLyxyrAVKmiIL9YMfhgt9A');
const stripePromise = loadStripe('pk_live_TcWOWQAbtdfIMZTc3iNMm6xK');

const CheckoutForm = (props) => {
    return (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <Form user={props.user} permit={props.permit} geocode={props.geocode} />
        </Elements>
    );
};

export default CheckoutForm;
