import Menu from './components/Menu';
import Dashboard from './pages/Dashboard';
import Permits from './pages/Permits';
import Announcements from './pages/Announcements';
import New from './pages/New';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import React, { useState, useEffect } from 'react';
import {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonLoading,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Settings from './pages/Settings';
import Success from './pages/Success';
import Permit from './pages/Permit';
import Exclusions from './pages/Blocked';
import firebaseApp from 'firebase';

import { PermitStore } from './store/PermitStore';
import { TownStore } from './store/TownStore';
import { getCurrentPermits, getPermits } from './utilityFunctions';

const fbDb = firebaseApp.firestore();
const App = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [user, loading, error] = useAuthState(firebaseApp.auth());
    const [admin, setAdmin] = useState(false);
    const [ready, setReady] = useState(false);
    const municipality = 'Cochrane';
    const logout = () => {
        firebaseApp.auth().signOut();
        window.location.reload();
    };
    useEffect(() => {
        getCurrentPermits();
        getPermits();
    }, []);
    // useEffect(() => {
    //     const currentPermitsArray = [];
    //     const getCurrentPermits = () => {
    //         fbDb.collection('permits')
    //             .get()
    //             .then((querySnapshot) => {
    //                 querySnapshot.forEach((doc) => {
    //                     const id = doc.id;
    //                     const data = doc.data();
    //                     // console.log(doc.id, doc.data());
    //                     if (moment(data.to).format() > moment().format()) {
    //                         currentPermitsArray.push({ id, ...data });
    //                     }
    //                 });
    //                 PermitStore.update((s) => {
    //                     s.currentPermits = currentPermitsArray;
    //                 });
    //             });
    //     };

    // }, []);
    useEffect(() => {
        const getUser = () => {
            if (user) {
                // console.log(user)
                fbDb.collection('users')
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.admin === true) {
                            console.log('admin');
                            setAdmin(true);
                        } else {
                            console.log('not admin');
                        }
                    })
                    .catch((err) => {
                        console.log(err, 'user not admin');
                    });
            }
        };
        getUser();
        setTimeout(() => {
            setReady(true);
        }, 2000);
    }, [user]);
    useEffect(() => {
        const getTownDoc = () => {
            // console.log(user)
            fbDb.collection('municipality')
                .doc(municipality)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        const id = doc.id;
                        const terms = data.terms;
                        const agree = data.agree;
                        const riskLevel = data.riskLevel;
                        const burnBan = data.fireban;
                        const mapsAPI = data.mapsAPI;
                        // console.log(data)
                        TownStore.update((s) => {
                            s.townDoc = { id, ...data };
                            s.riskLevel = riskLevel;
                            s.burnBan = burnBan;
                            s.mapsAPI = mapsAPI;
                        });
                        PermitStore.update((s) => {
                            s.terms = terms;
                            s.agree = agree;
                        });
                    } else {
                        TownStore.update((s) => {
                            s.townDoc = null;
                        });
                    }
                })
                .catch((error) => console.log(error));
        };
        const getPermitTypes = () => {
            // console.log(user)
            fbDb.collection('municipality')
                .doc(municipality)
                .collection('permitTypes')
                .get()
                .then((querySnapshot) => {
                    const types = [];
                    querySnapshot.forEach((doc) => {
                        const id = doc.id;
                        const data = doc.data();
                        // console.log(doc.id, " => ", doc.data());
                        types.push({ id, ...data });
                    });
                    PermitStore.update((s) => {
                        s.permitTypes = types;
                    });
                })
                .catch((error) => console.log(error));
        };
        const getAnnouncements = () => {
            firebaseApp
                .firestore()
                .collection('municipality')
                .doc(municipality)
                .collection('announcements')
                .limit(3)
                .get()
                .then((querySnapshot) => {
                    const announcementsList = [];
                    querySnapshot.forEach((doc) => {
                        const id = doc.id;
                        const data = doc.data();
                        // doc.data() is never undefined for query doc snapshots
                        announcementsList.push({ id, ...data });
                        // console.log(doc.id, " => ", doc.data());
                    });
                    const annoucementsSorted = announcementsList.sort((a, b) =>
                        a.dateCreated > b.dateCreated ? -1 : b.dateCreated > a.dateCreated ? 1 : 0
                    );
                    TownStore.update((s) => {
                        s.announcements = annoucementsSorted;
                    });
                });
        };
        getTownDoc();
        getPermitTypes();
        getAnnouncements();
    }, []);
    if (loading) {
        return (
            <IonApp>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={3000}
                />
            </IonApp>
        );
    }

    if (error) {
        return (
            <IonApp>
                <div>
                    <p>Error: {error}</p>
                </div>
            </IonApp>
        );
    }

    if (!user) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet id='main'>
                        <Redirect from='/' to='/login' exact />
                        <Redirect from='/dashboard' to='/login' />
                        <Redirect from='/permits' to='/login' />
                        <Redirect from='/permit' to='/login' />
                        <Redirect from='/permit/:id' to='/login' />
                        <Redirect from='/settings' to='/login' />
                        <Redirect from='/announcements' to='/login' />
                        <Route root path='/login' component={Login} exact />
                        <Route root path='/forgot' component={Forgot} exact />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }

    if (user && admin === false) {
        if (!ready) {
            return (
                <IonApp>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={600}
                    />
                </IonApp>
            );
        } else {
            return (
                <IonApp>
                    <IonContent>
                        <IonHeader collapse='condense'>
                            <IonToolbar>
                                <IonTitle size='large'>Nope.</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <p className='ion-padding ion-text-center'>
                            You do not have permission to access this page. If you believe this to
                            be an error, please contact your administrator.
                        </p>
                        <p className='ion-padding ion-text-center'>
                            <IonButton onClick={() => logout()}>Log Out</IonButton>{' '}
                        </p>
                    </IonContent>
                </IonApp>
            );
        }
    }
    if (user && admin) {
        if (!ready) {
            return (
                <IonApp>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={1000}
                    />
                </IonApp>
            );
        } else {
            return (
                <IonApp>
                    <IonReactRouter>
                        <IonSplitPane contentId='main'>
                            <Menu />
                            <IonRouterOutlet id='main'>
                                <Route path='/dashboard' component={Dashboard} exact />
                                <Route path='/permits' component={Permits} exact />
                                <Route path='/permits/new' component={New} exact />
                                <Route path='/exclusions' component={Exclusions} exact />
                                <Route path='/settings' component={Settings} exact />
                                <Route path='/announcements' component={Announcements} exact />
                                <Route path='/success/:id' component={Success} />
                                <Route path='/permit/:id' component={Permit} />
                                <Redirect from='/' to='/dashboard' exact />
                                <Redirect from='/login' to='/dashboard' exact />
                            </IonRouterOutlet>
                        </IonSplitPane>
                    </IonReactRouter>
                </IonApp>
            );
        }
    }
};

export default App;
